import { observer } from 'mobx-react';
import React, { Fragment, useState } from 'react';
import { ISpecificationCategoryModel } from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../service/specification/entity/ISpecificationEntityModel';
import { FormMultiAutocomplete } from '../../../../../design/form/FormMultiAutocomplete';
import { SpecificationFormSelectionDomain } from './SpecificationFormSelectionDomain';
import { SpecificationCategoryType } from '../../../../../../service/specification/category/ISpecificationCategoryModelConditionMap';
import { FormTextArea } from '../../../../../design/form/FormTextArea';
import { ApplicationDetailDomain } from '../../../../../user/page/application/info/store/ApplicationDetailDomain';

export interface SpecificationFormSelectionProperties {
  selectedSpecificationsIds: string[];
  specificationCategories: ISpecificationCategoryModel[];
  specifications: ISpecificationEntityModel[];
  onChange: (specificationIds: string[]) => void;
  disabled?: boolean;
  isShowAllConditionCategories?: boolean;
  componentFromType: 'fromApplicationCreate' | 'fromRequirements';
  applicationDetailDomain?: ApplicationDetailDomain
}

export const SpecificationFormSelection = observer((props: SpecificationFormSelectionProperties) => {
  const [domain] = useState<SpecificationFormSelectionDomain>(new SpecificationFormSelectionDomain());
  const applicationDetailDomain = props.applicationDetailDomain || null;
  return (
    <Fragment>
      {props.specificationCategories.map((specificationCategory) => {
        if (applicationDetailDomain !== null
          && props.componentFromType === 'fromApplicationCreate'
          && specificationCategory.categoryType === SpecificationCategoryType.specificationCategoryText) {
          const currentSavedField = applicationDetailDomain.ui.specificationsTextFieldsValues.list.find(value => value.categoryId === specificationCategory.id)
          const textFieldName = specificationCategory.isActive ? specificationCategory.name : specificationCategory.name + ' (неактивен)'
          const currentValue = currentSavedField ? currentSavedField.name : ''
          const isHaveError =
            applicationDetailDomain.ui.specificationsTextFieldsErrors.value.find(
              errorId => errorId === specificationCategory.id
            ) || null

          return (specificationCategory.isActive ?
            <FormTextArea
              value={currentValue || ''}
              onChange={(newValue) => {
                applicationDetailDomain.onEditCategoryTextField({ category: specificationCategory, newValue })
              }}
              label={textFieldName || ''}
              required={specificationCategory.conditionsTextMap?.isRequired}
              paddingSizing={1}
              size={'small'}
              autoRows={true}
              minRows={2}
              errorMessage={isHaveError ? 'Обязательно к заполнению (Минимум 2 символа)' : null}
            /> : currentValue ? <FormTextArea
              value={currentValue || ''}
              onChange={(newValue) => {
                applicationDetailDomain.onEditCategoryTextField({ category: specificationCategory, newValue })
              }}
              label={specificationCategory.name + ' (неактивен)' || ''}
              required={specificationCategory.conditionsTextMap?.isRequired}
              paddingSizing={1}
              size={'small'}
              rows='2'
              errorMessage={isHaveError ? 'Обязательно к заполнению (Минимум 2 символа)' : null}
            /> : <></>
          )
        } else {
          return (
            <SpecificationItem
              {...props}
              domain={domain}
              specificationCategory={specificationCategory}
              key={specificationCategory.id}
            />
          )
        }

      })}
    </Fragment>
  );
});

export const SpecificationItem = observer(
  ({
    selectedSpecificationsIds,
    specifications,
    onChange,
    disabled = false,
    domain,
    specificationCategory,
    isShowAllConditionCategories = false,
  }: {
    selectedSpecificationsIds: string[],
    specifications: any,
    onChange: any,
    disabled?: boolean,
    domain: SpecificationFormSelectionDomain,
    specificationCategory: ISpecificationCategoryModel,
    isShowAllConditionCategories?: boolean,
  }) => {
    const categoryValues = domain.specificationCategoriesValues(specifications, specificationCategory.id);
    let categorySelectedValues = selectedSpecificationsIds.filter((selectedSpecificationId) =>
      categoryValues.find((categoryValue) => categoryValue.value === selectedSpecificationId),
    );

    const isIncludeValueForCategoryTrueCondition =
      specificationCategory.conditionsMap?.useIfSpecificationEntitiesIds?.length || 0 > 0
        ? specificationCategory.conditionsMap?.useIfSpecificationEntitiesIds?.some((specificationId) =>
          selectedSpecificationsIds.includes(specificationId),
        )
        : true;

    const isIncludeValueForCategoryFalseCondition =
      specificationCategory.conditionsMap?.useIfNotSpecificationEntitiesIds?.length || 0 > 0
        ? specificationCategory.conditionsMap?.useIfNotSpecificationEntitiesIds?.some((specificationId) =>
          selectedSpecificationsIds.includes(specificationId),
        )
        : false;

    const conditionRenderStatus = isIncludeValueForCategoryTrueCondition && !isIncludeValueForCategoryFalseCondition;
    const isNeedToRender =
      (isShowAllConditionCategories || conditionRenderStatus) &&
      categoryValues.length > 0 &&
      (specificationCategory.isActive || categorySelectedValues.length > 0)
      && specificationCategory.categoryType === SpecificationCategoryType.specificationCategoryValue;

    if (!isNeedToRender) {
      const index = selectedSpecificationsIds.findIndex((item) => categorySelectedValues.includes(item));
      if (index !== -1) {
        selectedSpecificationsIds.splice(index, 1);
      }

      categorySelectedValues = [];
    }
    return isNeedToRender ? (
      <FormMultiAutocomplete
        key={specificationCategory.id}
        disabled={disabled}
        values={categoryValues}
        onChangeSelect={domain.getComplexMultiSelectFieldHandler(
          {
            selectedIds: selectedSpecificationsIds,
            selectedCategoryId: specificationCategory.id,
            selectedCategoryValues: categoryValues,
            onChange
          },
        )}
        selected={categorySelectedValues}
        label={
          specificationCategory.isActive
            ? specificationCategory.name || ''
            : `${specificationCategory.name} (неактивен)`
        }
        helperText={specificationCategory.description}
      />
    ) : (
      <div key={specificationCategory.id} />
    );
  },
);
